import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "affiliateUrl", 'clickConfirmation' ]

  copyUrlToClipboard() {
    const confirmationTarget = this.clickConfirmationTarget

    navigator.clipboard.writeText(this.affiliateUrlTarget.innerHTML).then(() => {
      confirmationTarget.classList.remove('hidden')
      setTimeout(function(){
        confirmationTarget.classList.add("hidden");
      }, 2000);
    })
  }
}
